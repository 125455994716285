import axios from 'axios';

export const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const runEvScan = async (username, password, queryRows, isLive) => {
	const queryRowsCopy = JSON.parse(JSON.stringify(queryRows));

	const sports = [];
	queryRowsCopy?.forEach((queryRow) => {
		const books = [];
		queryRow.book?.forEach((bk) => {
			// TODO: replace following code once fanduel is back
			if (bk.value === 'FanDuel') {
				books.push('Betway (Alaska)');
			} else {
				books.push(bk.value);
			}
		});
		queryRow.book = books;

		const sharps = [];
		queryRow.sharp?.forEach((bk) => {
			// TODO: replace following code once fanduel is back
			if (bk.value === 'FanDuel') {
				sharps.push('Betway (Alaska)');
			} else {
				sharps.push(bk.value);
			}
		});
		queryRow.sharp = sharps;

		const minMax = [];
		queryRow.minMaxOdds?.forEach((m) => {
			minMax.push(Number(m));
		});

		const minMaxEv = [];

		queryRow.sport.forEach((sprt) => {
			sports.push(sprt.value);
		});

		queryRow.minMaxEv?.forEach((m) => {
			minMaxEv.push(Number(m));
		});

		queryRow.minMaxOdds = minMax;
		queryRow.minMaxEv = minMaxEv;

		if (new Date(queryRow.date) <= new Date()) {
			// prevent date from being in the past
			const tomorrow = new Date();
			tomorrow.setDate(tomorrow.getDate() + 1);
			queryRow.date = tomorrow;
		}
	});
	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	await axios.get(`${backendUrl}/evScreener?queryRows=${JSON.stringify(queryRowsCopy)}&username=${username}&password=${password}&is_live=${isLive}`).data;
	await delay(250);
	let pingStatusResponse = (await axios.get(`${backendUrl}/ev_scan_results?username=${username}&password=${password}`)).data;
	// console.log('pingStatusResponse: ', pingStatusResponse);

	// console.log('sports', sports);
	if (sports.includes('basketball') || sports.length === 0) {
		let count = 0;
		while (pingStatusResponse.response_loading_basketball && count < 10) {
			await delay(800);
			pingStatusResponse = (await axios.get(`${backendUrl}/ev_scan_results?username=${username}&password=${password}`)).data;
			// console.log('pingStatusResponse: ', pingStatusResponse);
			count += 1;
		}
	}
	// if (sports.includes('soccer') || sports.length === 0) {
	// 	// console.log('repeating soccer');
	//     let count = 0;
	// 	while (pingStatusResponse.response_loading_soccer && count < 10) {
	// 		await delay(800);
	// 		pingStatusResponse = (await axios.get(`${backendUrl}/ev_scan_results?username=${username}&password=${password}`)).data;
	// 		// console.log('pingStatusResponse: ', pingStatusResponse);
	//         count += 1;
	// 	}
	// }
	// console.log((sports.some((sport) => sport !== 'soccer' && sport !== 'basketball')) || sports.length === 0, (!sports.includes('soccer') && !sports.includes('basketball')) || sports.length === 0)
	if (sports.some((sport) => sport !== 'soccer' && sport !== 'basketball') || sports.length === 0) {
		let count = 0;
		while (pingStatusResponse.response_loading_other && count < 10) {
			await delay(800);
			pingStatusResponse = (await axios.get(`${backendUrl}/ev_scan_results?username=${username}&password=${password}`)).data;
			// console.log('pingStatusResponse: ', pingStatusResponse);
			count += 1;
		}
	}

	const returnArr = [];

	if (sports.includes('basketball') || sports.length === 0) {
		for (let key in pingStatusResponse.response_basketball) {
			try {
				if (pingStatusResponse.response_basketball[key].market === 'Point Spread') {
					// console.log('pingStatusResponse.response_basketball[key]: ', pingStatusResponse.response_basketball[key]);
					try {
						pingStatusResponse.response_basketball[key].id = pingStatusResponse.response_basketball[key].outcome_id_normalized;
						pingStatusResponse.response_basketball[key].bet_type = pingStatusResponse.response_basketball[key].outcome_id_normalized.split(':')[1];
		
						if (pingStatusResponse.response_basketball[key].bet_price_over_negative_sharp && pingStatusResponse.response_basketball[key].ev_over_negative > pingStatusResponse.response_basketball[key].ev_over_positive && pingStatusResponse.response_basketball[key].ev_over_negative > pingStatusResponse.response_basketball[key].ev_under_negative && pingStatusResponse.response_basketball[key].ev_over_negative > pingStatusResponse.response_basketball[key].ev_under_positive) {
							pingStatusResponse.response_basketball[key].ev = pingStatusResponse.response_basketball[key].ev_over_negative
							pingStatusResponse.response_basketball[key].quarter_kelly = pingStatusResponse.response_basketball[key].quarter_kelly_over_negative;
							pingStatusResponse.response_basketball[key].selected_sharp_price = pingStatusResponse.response_basketball[key].bet_price_over_negative_sharp;
							pingStatusResponse.response_basketball[key].sharp_prices = `${pingStatusResponse.response_basketball[key].bet_price_over_negative_sharp?.toFixed(0)} / ${pingStatusResponse.response_basketball[key].bet_price_under_positive_sharp?.toFixed(0) || ''}`;
							pingStatusResponse.response_basketball[key].bet_price = pingStatusResponse.response_basketball[key].bet_price_over_negative_dumb?.toFixed(0)
							pingStatusResponse.response_basketball[key].type = 'bet_price_over_negative_sharp'

							returnArr.push(pingStatusResponse.response_basketball[key]);

						} else if (pingStatusResponse.response_basketball[key].bet_price_over_positive_sharp && pingStatusResponse.response_basketball[key].ev_over_positive > pingStatusResponse.response_basketball[key].ev_over_negative && pingStatusResponse.response_basketball[key].ev_over_positive > pingStatusResponse.response_basketball[key].ev_under_negative && pingStatusResponse.response_basketball[key].ev_over_positive > pingStatusResponse.response_basketball[key].ev_under_positive) {
							pingStatusResponse.response_basketball[key].ev = pingStatusResponse.response_basketball[key].ev_over_negative
							pingStatusResponse.response_basketball[key].quarter_kelly = pingStatusResponse.response_basketball[key].quarter_kelly_over_negative;
							pingStatusResponse.response_basketball[key].selected_sharp_price = pingStatusResponse.response_basketball[key].bet_price_over_positive_sharp;
							pingStatusResponse.response_basketball[key].sharp_prices = `${pingStatusResponse.response_basketball[key].bet_price_over_positive_sharp?.toFixed(0)} / ${pingStatusResponse.response_basketball[key].bet_price_under_negative_sharp?.toFixed(0) || ''}`;
							pingStatusResponse.response_basketball[key].bet_price = pingStatusResponse.response_basketball[key].bet_price_over_positive_dumb?.toFixed(0)
							pingStatusResponse.response_basketball[key].type = 'bet_price_over_positive_sharp'

							returnArr.push(pingStatusResponse.response_basketball[key]);

						} else if (pingStatusResponse.response_basketball[key].bet_price_under_negative_sharp && pingStatusResponse.response_basketball[key].ev_under_negative > pingStatusResponse.response_basketball[key].ev_over_positive && pingStatusResponse.response_basketball[key].ev_under_negative > pingStatusResponse.response_basketball[key].ev_over_negative && pingStatusResponse.response_basketball[key].ev_under_negative > pingStatusResponse.response_basketball[key].ev_under_positive) {
							pingStatusResponse.response_basketball[key].ev = pingStatusResponse.response_basketball[key].ev_under_negative
							pingStatusResponse.response_basketball[key].quarter_kelly = pingStatusResponse.response_basketball[key].quarter_kelly_under_negative;
							pingStatusResponse.response_basketball[key].selected_sharp_price = pingStatusResponse.response_basketball[key].bet_price_under_negative_sharp;
							pingStatusResponse.response_basketball[key].sharp_prices = `${pingStatusResponse.response_basketball[key].bet_price_under_negative_sharp?.toFixed(0)} / ${pingStatusResponse.response_basketball[key].bet_price_over_positive_sharp?.toFixed(0) || ''}`;
							pingStatusResponse.response_basketball[key].bet_price = pingStatusResponse.response_basketball[key].bet_price_under_negative_dumb?.toFixed(0)
							pingStatusResponse.response_basketball[key].type = 'bet_price_under_negative_sharp'

							returnArr.push(pingStatusResponse.response_basketball[key]);

						} else if (pingStatusResponse.response_basketball[key].bet_price_under_positive_sharp && pingStatusResponse.response_basketball[key].ev_under_positive > pingStatusResponse.response_basketball[key].ev_over_negative && pingStatusResponse.response_basketball[key].ev_under_positive > pingStatusResponse.response_basketball[key].ev_under_negative && pingStatusResponse.response_basketball[key].ev_under_positive > pingStatusResponse.response_basketball[key].ev_over_positive) {
							pingStatusResponse.response_basketball[key].ev = pingStatusResponse.response_basketball[key].ev_under_positive
							pingStatusResponse.response_basketball[key].quarter_kelly = pingStatusResponse.response_basketball[key].quarter_kelly_under_positive;
							pingStatusResponse.response_basketball[key].selected_sharp_price = pingStatusResponse.response_basketball[key].bet_price_under_positive_sharp;
							pingStatusResponse.response_basketball[key].sharp_prices = `${pingStatusResponse.response_basketball[key].bet_price_under_positive_sharp?.toFixed(0)} / ${pingStatusResponse.response_basketball[key].bet_price_over_negative_sharp?.toFixed(0) || ''}`;
							pingStatusResponse.response_basketball[key].bet_price = pingStatusResponse.response_basketball[key].bet_price_under_positive_dumb?.toFixed(0)
							pingStatusResponse.response_basketball[key].type = 'bet_price_under_positive_sharp'

							returnArr.push(pingStatusResponse.response_basketball[key]);
						}
	
					} catch(e) {
						console.error(e)
					}
					
				}

				pingStatusResponse.response_basketball[key].id = pingStatusResponse.response_basketball[key].outcome_id_normalized;
				pingStatusResponse.response_basketball[key].bet_type = pingStatusResponse.response_basketball[key].outcome_id_normalized.split(':')[1];
				const formattedPriceOverSharp =
					pingStatusResponse.response_basketball[key].bet_price_over_sharp > 0
						? `+${pingStatusResponse.response_basketball[key].bet_price_over_sharp?.toFixed(0)}`
						: pingStatusResponse.response_basketball[key].bet_price_over_sharp?.toFixed(0);
				const formattedPriceUnderSharp =
					pingStatusResponse.response_basketball[key].bet_price_under_sharp > 0
						? `+${pingStatusResponse.response_basketball[key].bet_price_under_sharp.toFixed(0)}`
						: pingStatusResponse.response_basketball[key].bet_price_under_sharp?.toFixed(0);

				if (pingStatusResponse.response_basketball[key].ev_over > pingStatusResponse.response_basketball[key].ev_under) {
					pingStatusResponse.response_basketball[key].bet_price =
						pingStatusResponse.response_basketball[key].bet_price_over_dumb.toFixed(0) > 0
							? `+${pingStatusResponse.response_basketball[key].bet_price_over_dumb.toFixed(0)}`
							: pingStatusResponse.response_basketball[key].bet_price_over_dumb.toFixed(0);
					pingStatusResponse.response_basketball[key].sharp_prices = `${formattedPriceOverSharp} / ${formattedPriceUnderSharp}`;
					pingStatusResponse.response_basketball[key].quarter_kelly = pingStatusResponse.response_basketball[key].quarter_kelly_over;
					pingStatusResponse.response_basketball[key].ev = pingStatusResponse.response_basketball[key].ev_over;
					pingStatusResponse.response_basketball[key].selected_sharp_price = pingStatusResponse.response_basketball[key].bet_price_over_sharp;

					const splitId = pingStatusResponse.response_basketball[key].outcome_id_normalized.split(':');
					pingStatusResponse.response_basketball[key].bet_name = `${splitId[2]} over ${splitId[3]}`;
				} else {
					pingStatusResponse.response_basketball[key].bet_price =
						pingStatusResponse.response_basketball[key].bet_price_under_dumb.toFixed(0) > 0
							? `+${pingStatusResponse.response_basketball[key].bet_price_under_dumb.toFixed(0)}`
							: pingStatusResponse.response_basketball[key].bet_price_under_dumb.toFixed(0);
					pingStatusResponse.response_basketball[key].sharp_prices = `${formattedPriceUnderSharp} / ${formattedPriceOverSharp}`;
					pingStatusResponse.response_basketball[key].quarter_kelly = pingStatusResponse.response_basketball[key].quarter_kelly_under;
					pingStatusResponse.response_basketball[key].ev = pingStatusResponse.response_basketball[key].ev_under;
					pingStatusResponse.response_basketball[key].selected_sharp_price = pingStatusResponse.response_basketball[key].bet_price_under_sharp;

					const splitId = pingStatusResponse.response_basketball[key].outcome_id_normalized.split(':');
					pingStatusResponse.response_basketball[key].bet_name = `${splitId[2]} under ${splitId[3]}`;
				}
				// TODO: replace following code once fanduel is back
				if (pingStatusResponse.response_basketball[key].sportsbook_dumb === 'Betway (Alaska)') {
					pingStatusResponse.response_basketball[key].sportsbook_dumb = 'FanDuel';
				}
				if (pingStatusResponse.response_basketball[key].sportsbook_sharp === 'Betway (Alaska)') {
					pingStatusResponse.response_basketball[key].sportsbook_sharp = 'FanDuel';
				}

				returnArr.push(pingStatusResponse.response_basketball[key]);
			} catch (e) {}
		}
	}

	if (sports.includes('soccer') || sports.length === 0) {
		for (let key in pingStatusResponse.response_soccer) {
			try {
				pingStatusResponse.response_soccer[key].id = pingStatusResponse.response_soccer[key].outcome_id_normalized;
				pingStatusResponse.response_soccer[key].bet_type = pingStatusResponse.response_soccer[key].outcome_id_normalized.split(':')[1];
				const formattedPriceOverSharp =
					pingStatusResponse.response_soccer[key].bet_price_over_sharp > 0
						? `+${pingStatusResponse.response_soccer[key].bet_price_over_sharp?.toFixed(0)}`
						: pingStatusResponse.response_soccer[key].bet_price_over_sharp?.toFixed(0);
				const formattedPriceUnderSharp =
					pingStatusResponse.response_soccer[key].bet_price_under_sharp > 0
						? `+${pingStatusResponse.response_soccer[key].bet_price_under_sharp.toFixed(0)}`
						: pingStatusResponse.response_soccer[key].bet_price_under_sharp?.toFixed(0);

				if (pingStatusResponse.response_soccer[key].ev_over > pingStatusResponse.response_soccer[key].ev_under) {
					pingStatusResponse.response_soccer[key].bet_price =
						pingStatusResponse.response_soccer[key].bet_price_over_dumb.toFixed(0) > 0
							? `+${pingStatusResponse.response_soccer[key].bet_price_over_dumb.toFixed(0)}`
							: pingStatusResponse.response_soccer[key].bet_price_over_dumb.toFixed(0);
					pingStatusResponse.response_soccer[key].sharp_prices = `${formattedPriceOverSharp} / ${formattedPriceUnderSharp}`;
					pingStatusResponse.response_soccer[key].quarter_kelly = pingStatusResponse.response_soccer[key].quarter_kelly_over;
					pingStatusResponse.response_soccer[key].ev = pingStatusResponse.response_soccer[key].ev_over;
					pingStatusResponse.response_soccer[key].selected_sharp_price = pingStatusResponse.response_soccer[key].bet_price_over_sharp;

					const splitId = pingStatusResponse.response_soccer[key].outcome_id_normalized.split(':');
					pingStatusResponse.response_soccer[key].bet_name = `${splitId[2]} over ${splitId[3]}`;
				} else {
					pingStatusResponse.response_soccer[key].bet_price =
						pingStatusResponse.response_soccer[key].bet_price_under_dumb.toFixed(0) > 0
							? `+${pingStatusResponse.response_soccer[key].bet_price_under_dumb.toFixed(0)}`
							: pingStatusResponse.response_soccer[key].bet_price_under_dumb.toFixed(0);
					pingStatusResponse.response_soccer[key].sharp_prices = `${formattedPriceUnderSharp} / ${formattedPriceOverSharp}`;
					pingStatusResponse.response_soccer[key].quarter_kelly = pingStatusResponse.response_soccer[key].quarter_kelly_under;
					pingStatusResponse.response_soccer[key].ev = pingStatusResponse.response_soccer[key].ev_under;
					pingStatusResponse.response_soccer[key].selected_sharp_price = pingStatusResponse.response_soccer[key].bet_price_under_sharp;

					const splitId = pingStatusResponse.response_soccer[key].outcome_id_normalized.split(':');
					pingStatusResponse.response_soccer[key].bet_name = `${splitId[2]} under ${splitId[3]}`;
				}
				// TODO: replace following code once fanduel is back
				if (pingStatusResponse.response_soccer[key].sportsbook_dumb === 'Betway (Alaska)') {
					pingStatusResponse.response_soccer[key].sportsbook_dumb = 'FanDuel';
				}
				if (pingStatusResponse.response_soccer[key].sportsbook_sharp === 'Betway (Alaska)') {
					pingStatusResponse.response_soccer[key].sportsbook_sharp = 'FanDuel';
				}

				returnArr.push(pingStatusResponse.response_soccer[key]);
			} catch (e) {}
		}
	}

	if (sports.some((sport) => sport !== 'soccer' && sport !== 'basketball') || sports.length === 0) {
		for (let key in pingStatusResponse.response_other) {
			try {
				pingStatusResponse.response_other[key].id = pingStatusResponse.response_other[key].outcome_id_normalized;
				pingStatusResponse.response_other[key].bet_type = pingStatusResponse.response_other[key].outcome_id_normalized.split(':')[1];
				const formattedPriceOverSharp =
					pingStatusResponse.response_other[key].bet_price_over_sharp > 0
						? `+${pingStatusResponse.response_other[key].bet_price_over_sharp?.toFixed(0)}`
						: pingStatusResponse.response_other[key].bet_price_over_sharp?.toFixed(0);
				const formattedPriceUnderSharp =
					pingStatusResponse.response_other[key].bet_price_under_sharp > 0
						? `+${pingStatusResponse.response_other[key].bet_price_under_sharp.toFixed(0)}`
						: pingStatusResponse.response_other[key].bet_price_under_sharp?.toFixed(0);

				if (pingStatusResponse.response_other[key].ev_over > pingStatusResponse.response_other[key].ev_under) {
					pingStatusResponse.response_other[key].bet_price =
						pingStatusResponse.response_other[key].bet_price_over_dumb.toFixed(0) > 0
							? `+${pingStatusResponse.response_other[key].bet_price_over_dumb.toFixed(0)}`
							: pingStatusResponse.response_other[key].bet_price_over_dumb.toFixed(0);
					pingStatusResponse.response_other[key].sharp_prices = `${formattedPriceOverSharp} / ${formattedPriceUnderSharp}`;
					pingStatusResponse.response_other[key].quarter_kelly = pingStatusResponse.response_other[key].quarter_kelly_over;
					pingStatusResponse.response_other[key].ev = pingStatusResponse.response_other[key].ev_over;
					pingStatusResponse.response_other[key].selected_sharp_price = pingStatusResponse.response_other[key].bet_price_over_sharp;

					const splitId = pingStatusResponse.response_other[key].outcome_id_normalized.split(':');
					pingStatusResponse.response_other[key].bet_name = `${splitId[2]} over ${splitId[3]}`;
				} else {
					pingStatusResponse.response_other[key].bet_price =
						pingStatusResponse.response_other[key].bet_price_under_dumb.toFixed(0) > 0
							? `+${pingStatusResponse.response_other[key].bet_price_under_dumb.toFixed(0)}`
							: pingStatusResponse.response_other[key].bet_price_under_dumb.toFixed(0);
					pingStatusResponse.response_other[key].sharp_prices = `${formattedPriceUnderSharp} / ${formattedPriceOverSharp}`;
					pingStatusResponse.response_other[key].quarter_kelly = pingStatusResponse.response_other[key].quarter_kelly_under;
					pingStatusResponse.response_other[key].ev = pingStatusResponse.response_other[key].ev_under;
					pingStatusResponse.response_other[key].selected_sharp_price = pingStatusResponse.response_other[key].bet_price_under_sharp;

					const splitId = pingStatusResponse.response_other[key].outcome_id_normalized.split(':');
					pingStatusResponse.response_other[key].bet_name = `${splitId[2]} under ${splitId[3]}`;
				}
				// TODO: replace following code once fanduel is back
				if (pingStatusResponse.response_other[key].sportsbook_dumb === 'Betway (Alaska)') {
					pingStatusResponse.response_other[key].sportsbook_dumb = 'FanDuel';
				}
				if (pingStatusResponse.response_other[key].sportsbook_sharp === 'Betway (Alaska)') {
					pingStatusResponse.response_other[key].sportsbook_sharp = 'FanDuel';
				}

				returnArr.push(pingStatusResponse.response_other[key]);
			} catch (e) {}
		}
	}

	return returnArr.sort((a, b) => b.ev - a.ev);
};